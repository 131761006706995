import { Drawer } from 'antd';
import { renderIconV2 } from "src/utils/functions/filterFunction.js"
import * as comp from './layouts';
import DownOutlined from '@ant-design/icons/DownOutlined';

const DrawerComp = (props) => {
  const Component = comp[comp[props?.dataProps?.comp] ? props?.dataProps?.comp : "Empty"]
  return (
    <Drawer {...props?.dataProps}>
      <div
        onClick={() => props?.dataProps.onClose()}
        style={{ width: "100%", cursor: "pointer", height: 30 }}>
        <span style={{ fontSize: 16, paddingLeft: 10 }}>{props?.dataProps?.item?.param?.projectCode ? (props?.dataProps?.item?.param?.projectCode + " - " + props?.dataProps?.item?.param?.woOrderNumber + " - " + props?.dataProps?.item?.param?.productName) : ""}</span>
        <span style={{ float: "right" }}><DownOutlined style={{ fontSize: 32 }} /></span>
      </div>
      {props?.dataProps && <Component dataProps={props?.dataProps} />}
    </Drawer>
  )
}

export default DrawerComp;
