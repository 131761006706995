import React, { useContext } from "react";
import { Button } from 'antd';
import { MainContext } from 'src/Context';
// import { TiBackspaceOutline } from 'react-icons-ng/ti';
import CaretLeftOutlined from '@ant-design/icons/CaretLeftOutlined';

const App = ({ labelButton, style, pressbutton, handleSubmit, backState, newContext }) => {
  let context = useContext(MainContext);
  if (!context) {
    context = newContext
  }
  const lang = context?.state?.lang
  return (
    <div style={style.login}>
      <div style={style.login.rowNumPad} className="row">
        <div style={style.login.rowNumPad.col} onClick={() => pressbutton(1)} className="col-md-4"><Button variant="light" style={style.login.rowNumPad.Button}>1</Button></div>
        <div style={style.login.rowNumPad.col} onClick={() => pressbutton(2)} className="col-md-4"><Button variant="light" style={style.login.rowNumPad.Button}>2</Button></div>
        <div style={style.login.rowNumPad.col} onClick={() => pressbutton(3)} className="col-md-4"><Button variant="light" style={style.login.rowNumPad.Button}>3</Button></div>
      </div>
      <br />
      <div style={style.login.rowNumPad} className="row">
        <div style={style.login.rowNumPad.col} onClick={() => pressbutton(4)} className="col-md-4"><Button variant="light" style={style.login.rowNumPad.Button}>4</Button></div>
        <div style={style.login.rowNumPad.col} onClick={() => pressbutton(5)} className="col-md-4"><Button variant="light" style={style.login.rowNumPad.Button}>5</Button></div>
        <div style={style.login.rowNumPad.col} onClick={() => pressbutton(6)} className="col-md-4"><Button variant="light" style={style.login.rowNumPad.Button}>6</Button></div>
      </div>
      <br />
      <div style={style.login.rowNumPad} className="row">
        <div style={style.login.rowNumPad.col} onClick={() => pressbutton(7)} className="col-md-4"><Button variant="light" style={style.login.rowNumPad.Button}>7</Button></div>
        <div style={style.login.rowNumPad.col} onClick={() => pressbutton(8)} className="col-md-4"><Button variant="light" style={style.login.rowNumPad.Button}>8</Button></div>
        <div style={style.login.rowNumPad.col} onClick={() => pressbutton(9)} className="col-md-4"><Button variant="light" style={style.login.rowNumPad.Button}>9</Button></div>
      </div>
      <br />
      <div style={style.login.rowNumPad} className="row">
        <div style={style.login.rowNumPad.col} onClick={() => pressbutton(0)} className="col-md-8"><Button variant="light" style={style.login.rowNumPad.Button.nul}>0</Button></div>
        <div style={style.login.rowNumPad.col} onClick={() => backState()} className="col-md-4"><Button variant="light" style={style.login.rowNumPad.Button}><CaretLeftOutlined /></Button></div>
      </div>
      <br />
      <div style={style.login.rowNumPad} className="row">
        <div
          className="col-md-12">
          <Button
            variant="success"
            style={{ background: "#198754", width: '100%', fontSize: 30, height: 60, color: "#ffffff" }}
            onClick={() => handleSubmit()}>
            {lang?.[labelButton] ?? labelButton}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default App;
