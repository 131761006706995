/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react'
import { MainContext } from 'src/Context';
import { Row, Col } from 'react-bootstrap';
import { Modal, Button, Drawer, List, Card, Divider } from 'antd';
import { allinPut, allinOne, ItemCatalog, renderIconV2, limitText, removeDuplicateObj, sortNumAlpObj } from "src/utils/functions/filterFunction"
import { UserSwitchOutlined } from '@ant-design/icons';
import { content } from "src/utils/functions/core"
import { socket } from 'src/utils/socket';
const { v4: uuidv4 } = require('uuid');

class Pause extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = { ...this.props, dynamicDivHeight: 0 }
  }

  componentDidMount() {
    this._isMounted = true
    this.updateDynamicDivHeight();
    window.addEventListener('resize', this.updateDynamicDivHeight);
    this.getData()
  }

  // componentDidUpdate(prevProps) {
  //   // Check if the content has changed, then update the dynamic div height
  //   if (prevProps.content !== this.props.content) {
  //     this.updateDynamicDivHeight();
  //   }
  // }

  componentWillUnmount() {
    this._isMounted = false
    socket.off("sentToKafka")
    // window.removeEventListener('resize', this.updateDynamicDivWidth);
  }

  updateDynamicDivHeight = (e) => {
    // console.log()
    // Accessing the width of the dynamic div after the component has mounted or on window resize
    // const dynamicDivHeight = this.dynamicDiv.offsetWidth;
    // this.setState({ dynamicDivHeight });
  };

  getData = async () => {
    const { dataProps } = this.state
    const { undefObjHandler, querySelect, authToken, lang } = this.context.state
    const { user } = content()
    const eventConfigId = undefObjHandler(dataProps, "eventConfigType") ? dataProps.children.eventConfigType : ""
    const _user = await ItemCatalog("User", authToken, "param.pauseStatus AS pauseStatus, param", user.userId)
    if (dataProps && dataProps.drawer) {
      this.setState({ drawer: dataProps.drawer, _event: _user.param._pauseStatus.data })
    }
    let buttons = []
    if (this._isMounted) {
      const result = await allinOne("getDefaultValues", {}, authToken)
      querySelect({
        url: "/select_custom_catalog",
        token: authToken,
        type: "EventConfig",
        keys: "i4c.*",
        extraQuery: 'AND activeStatus = true AND archiveStatus = false AND eventType.`$ref` = ' + '"' + eventConfigId + '"'
      }).then(res => {
        let categories = []
        categories.push({
          name: lang?.["close"] ?? "close",
          type: "close",
          style: {
            background: "#b97676",
            fontSize: 26,
            minHeight: 110,
            color: "#ffffff"
          }
        })
        res.map((el, i) => {
          categories.push({
            name: result?.param?.kartemCodeGroup[el?.code],
            type: "filter",
            code: el?.code,
            style: {
              background: "#dddddd",
              fontSize: 18,
              minHeight: 110,
            }
          })
        })
        if (this._isMounted && res) {
          const data = res.filter(el=>el?.subCode !=="9")
          data.forEach((el, i) => {
            buttons.push({
              // name: limitText(el.subject, 20),
              name: el.subject,
              code: el?.code,
              subCode: el?.subCode,
              type: "subject",
              props: {
                style: {
                  background: "#dddddd",
                  fontSize: 20,
                  minHeight: 150,
                },
              },
              value: { ...el, type: el._type }
            })
          });
          this.setState({
            buttons,
            _buttons: buttons,
            pauseStatus: _user.pauseStatus,
            modal: dataProps.modal,
            kartemCodeGroup: removeDuplicateObj(categories, "name")
          })
          this.spinner = false
        }
      })
    }
  }

  setPauseToUser = async (el, us) => {
    // console.log({ el, us })
    const { dataProps, callback, trigger } = this.state
    const { item } = this.state.dataProps
    const { children } = this.props
    const { userConfig, lang, assetId } = this.context.state
    const dateNow = Date.now()
    const id = uuidv4()
    const userId = us?.userId
    const noAsset = (children && children.defaultEmptyAsset) ? children.defaultEmptyAsset : ""
    const pauseStatus = (el.value.subCode !== "0") ? true : false
    const _pauseStatus = {
      data: {
        eventStart: dateNow,
        event: el,
        asset: dataProps.props
      },
      assetId: assetId ? assetId : noAsset,
      messageId: id,
      parameters: {
        trigger: el.value.trigger,
        rfp_dataTimestamp: Date.now(),
        rfp_userInterrupt: el.value.subCode,
        rfp_userInterruptCode: el.value.code,
        rfp_userInterruptSubCode: el.value.subCode,
      }
    }
    const data = {
      topic: "raw_data",
      assetId: assetId ? assetId : noAsset,
      userId,
      messageId: id,
      parameters: {
        trigger: el.value.trigger,
        rfp_dataTimestamp: Date.now(),
        rfp_userInterrupt: el.value.subCode,
        rfp_userInterruptCode: el.value.code,
        rfp_userInterruptSubCode: el.value.subCode,
      }
    }
    socket.emit("sentToKafka", { ...data })
    // const r = await allinPut("updatePauseStatus", { userId, pauseStatus, _pauseStatus })
    // console.log(r)
  }

  updateUsersOnAsset = async (usersA, now, assets, event) => {
    const { assetId } = this.context.state
    const { callback, dataProps } = this.state
    // const workers = usersA.map((el) => {
    //   return ({
    //     ...el,
    //     eventStartTimestamp: now,
    //     code: event?.code,
    //     subCode: event?.subCode
    //   })
    // })
    // const result = await allinPut("updateUsersOnAsset", { assetId, workers })
    // // console.log(result)
    // if (result?.success) {
    //   const newData = {
    //     ...assets,
    //     [assetId]: workers
    //   }
      // socket.emit("usersOn", newData)
      callback({ type: "pauseStatus", ...dataProps.props })
    // }
    // callback({ type: "pauseStatus", ...dataProps.props })
  }

  setData = async (el) => {
    const { assetId } = this.context.state
    const now = Date.now()
    const delay = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    // console.log(el?.value)
    // socket.connect()
    if (false) {
    // if (el?.value?.causesStoppage) {
      const result = await allinOne("getSpecDocument", {})
      if (result) {
        const usersA = result.assets?.[assetId] ? result.assets?.[assetId] : []
        this.updateUsersOnAsset(usersA, now, result.asset, el?.value)
        for (let i = 0; i < usersA.length; i++) {
          this.setPauseToUser(el, usersA[i])
          // await delay(500);
        }
        this.setState({ modal: false })
      }
    } else {
      const { confirm } = Modal
      const result = await allinOne("getSpecDocument", {})
      if (result) {
        const usersA = result.assets?.[assetId] ? result.assets?.[assetId] : []
        // this.updateUsersOnAsset(usersA, now, result.asset)
        if (usersA && usersA.length > 1) {
          const n = await import("src/layouts/widgets/Companies/i4c_V2/components/Generic/NoIcon")
          const NoIcon = n?.default
          const us = await import("src/layouts/widgets/Companies/i4c_V2/main/UsersSelection")
          const UsersSelection = us?.default
          confirm({
            icon: <NoIcon />,
          width: "90%",
          style: { minWidth: 1000 },
            content: <UsersSelection
            users={usersA}
            event={el}
            context={this.context}
            setPauseToUser={this.setPauseToUser}
            onClose={() => Modal.destroyAll()}
            />,
            cancelButtonProps: { type: "primary", danger: true, ghost: false },
            cancelButtonProps: { style: { display: 'none', marginTop: 0 } },
            okButtonProps: { style: { display: 'none', marginTop: 0 } },
            // content: <Button onClick={destroyAll}>Click to destroy all</Button>,
            onOk() { },
            onCancel() {
              console.log('Cancel');
            }
          })

        } else {
          // only 1 user (current)
          this.updateUsersOnAsset(usersA, now, result.asset, el?.value)
          for (let i = 0; i < usersA.length; i++) {
            this.setPauseToUser(el, usersA[i])
            // await delay(500);
          }
          this.setState({ modal: false })
        }
      }
    }
  }

  handleOption = async (el) => {
    const { callback, dataProps } = this.state
    if (el.name === "cancelPause") {
      // this.setState({ modal: false, drawer: false })
      this.setState({ modal: false, drawer: false }, callback({ type: "pauseStatus", ...dataProps.props }))
    } else if (el === "stopPause") {
      // const { socketConn, userConfig, queryPut, authToken, lang } = this.context.state
      // const { user } = content()
      // const _user = await ItemCatalog("User", authToken, "param", user.userId)
      //
      // if (_user && _user.param && _user.param.pauseStatus) {
      //   const _param = {
      //     ..._user.param,
      //     pauseStatus: false,
      //     _pauseStatus: {}
      //   }
      //   queryPut({
      //     url: "/updateParam",
      //     token: authToken,
      //     type: "User",
      //     id: user.userId,
      //     param: _param
      //   }).then((res) => {
      //     if (this._isMounted && res) {
      //       this.setState({ modal: false, drawer: false })
      //       const data = {
      //         ...user.param,
      //         ..._user.param._pauseStatus,
      //         topic: "raw_data",
      //         userId: user.userId,
      //         parameters: {
      //           rfp_dataTimestamp: Date.now(),
      //           rfp_userInterrupt: 0,
      //           rfp_userInterruptCode: 0,
      //           rfp_userInterruptSubCode: 0,
      //         }
      //       }
      //       socketConn.emit("sentToKafka", { ...data })
      //       callback({ type: "pauseStatus", ...dataProps.props })
      //     }
      //   })
      // } else {
      //   console.log("something wrong")
      // }
    } else {
      // this._isMounted = true;
      // const { dataProps, pauseStatus, callback } = this.state
      // const { socketConn, userConfig, queryPut, authToken } = this.context.state
      // const { user } = userConfig
      // this.setState({ spinner: true })
      this.setData(el)
    }
  }

  logoutUser = () => {
    const { queryPut, authToken, userConfig } = this.context.state
    queryPut({
      url: "/userTrackUpdate",
      token: authToken,
      type: "UserTrack",
      id: userConfig.user.userId,
      // keys: "i4c.*",
    }).then(res => {
      if (res) {
        this.context.logOut(true)
      }
    })
  }

  handleCategories = (el, highlight) => {
    const { _buttons, dataProps, callback } = this.state
    // console.log(el)
    if (el?.type !== "close") {
      this.setState({
        highlight: (highlight === el?.name) ? null : el?.name,
        buttons: ((highlight === el?.name)) ? _buttons : _buttons.filter(f => (f?.code === el?.code))
      })
    } else {
      this.setState({ modal: false, drawer: false }, callback({ type: "pauseStatus", ...dataProps.props }))
    }
  }

  render() {
    const { style } = this.context
    const { lang, undefObjHandler, userConfig, dateTimeFormatter } = this.context.state
    const { user } = userConfig
    const { dynamicDivHeight, modal, drawer, buttons, children, _param, dataProps, eventConfig, _event, kartemCodeGroup, _buttons, highlight, callback } = this.state
    const pauseDetails = (children && children.param && children.param._pauseStatus) ? children.param._pauseStatus : undefObjHandler(_param, "_pauseStatus") && _param._pauseStatus
    const assetId = dataProps.props ? dataProps.props._id : ""
    const eventDetail = _event ? { ..._event, user } : false
    const sortKartemCodeGroup = kartemCodeGroup?.sort((a, b) => sortNumAlpObj(a, b, "code"))
    const sortButtons = buttons?.sort((a, b) => sortNumAlpObj(a, b, "code"))
    // console.log(this.props)
    const fixedRow = {
      background: "#e5e6e7",
      // position: "fixed",
      // // top: 0,
      // width: "100%",
      // zIndex: 1,
      // padding: "10px",
    }
    const scrollableRow = {
      // marginTop: 255, /* Adjust this value to match the height of your fixed row */
      // overflowY: "auto",
      // // position: "absolute",
      // // overflowX: "none",
      // height: "auto",
      // // height: "calc(100vh - 50px)", /* Adjust this value to leave space for the fixed row */
    }
    // console.log(sortKartemCodeGroup)
    return (
      <>
        <Drawer
          headerStyle={{ display: "none" }}
          height={"100%"}
          placement="bottom"
          destroyOnClose={true}
          onClose={() => {
            // this.setState({ modal: false })
            this.setState({ modal: false, drawer: false }, callback({ type: "pauseStatus", ...dataProps.props }))
          }}
          open={modal}>
          <div style={fixedRow}>
            <List
              // key={JSON.stringify({ ...state?.buttons })}
              grid={{ gutter: 0, xs: 1, sm: 2, md: 2, lg: 4, xl: 6, xxl: 6 }}
              dataSource={sortKartemCodeGroup}
              style={{ padding: "10px 0 0 0" }}
              // dataSource={[...state?.buttons, ...state?.buttons, ...state?.buttons]}
              renderItem={(el) => (
                <List.Item>
                  <Card
                    key={JSON.stringify(highlight)}
                    style={{
                      ...el?.style,
                      cursor: "pointer",
                      background: (el?.type === "close") ? "#ed2e2e" : ((el.name === highlight) ? "#40f02a" : "#aabee4")
                    }}
                    onClick={() => this.handleCategories(el, highlight)}
                  >
                    <div>{lang?.[el.name] ?? el.name} {el?.code ? " - " : ""} {el?.code}</div>
                    {/*
                    <div style={{ position: "absolute", bottom: 0, right: 10 }}>
                    <span style={{ fontSize: 12 }}>{lang?.["code"] ?? "code"}: </span>
                    <span>{el?.code}</span>
                  </div>
                  */}
                  </Card>
                </List.Item>
              )}
            />
          </div>
          <div style={scrollableRow}>
            <List
              // key={JSON.stringify({ ...state?.buttons })}
              grid={{ gutter: 0, xs: 1, sm: 2, md: 2, lg: 4, xl: 6, xxl: 6 }}
              dataSource={sortButtons}
              style={{ padding: "20px 0 0 0" }}
              // dataSource={[...state?.buttons, ...state?.buttons, ...state?.buttons]}
              renderItem={(el) => (
                <List.Item>
                  <Card
                    style={{ ...el?.props?.style, cursor: "pointer" }}
                    onClick={() => this.handleOption(el)}>
                    <div>{lang?.[el.name] ?? el.name}</div>
                    <div style={{ position: "absolute", bottom: 0, right: 10 }}>
                      <span style={{ fontSize: 12 }}>{lang?.["code"] ?? "code"}: </span>
                      <span>{el?.code}</span>
                    </div>
                  </Card>
                </List.Item>
              )}
            />
          </div>
        </Drawer>
      </>
    )
  }
}

export default Pause;
