import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from 'src/Context';
import { Image, Button, Tooltip } from 'antd';
import { allinOne, ItemCatalog, openNotification, hideNotification, renderIconV2, checkMobile, limitText } from "src/utils/functions/filterFunction"
import widgets from "src/layouts/widgets"
import { content } from "src/utils/functions/core"
import { updateUserAssetMgmt } from "src/layouts/widgets/Companies/i4c_V2/components/Generic/worker"
// import * as comp from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { UserOutlined } from '@ant-design/icons';
import Spinner from "src/layouts/components/spinner"
import { socket } from 'src/utils/socket';
import Header from 'src/layouts/widgets/Core/Header';
import FloatContent from 'src/layouts/widgets/Core/FloatContent';
// import AssetDashboard from 'src/layouts/widgets/Core/AssetDashboard';

class DashboardV2 extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      Component: widgets["Assets"],
      sequence: 0,
      selected: false,
      fullscreen: false,
      indexKey: Math.random(),
      usersOn: [],
      isLoading: false,
      ModalUsersOn: false
    }
    this.handleFullScreen = this.handleFullScreen.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.myDivRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
  }

  handleFullScreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
    this.setState({ fullscreen: true })
  }

  escFunction = (event) => {
    // console.log(event)
    if (event.key === "Escape") {
      this.setState({ fullscreen: false })
      this.context.contextCall("fullScreen", false)
    }
  }

  componentDidMount = () => {
    this._isMounted = true
    this.getData()
    const divHeight = this.myDivRef.current.offsetHeight;
    this.setState({
      divHeight: (divHeight + 39)
    })
    window.addEventListener('resize', this.handleResize);
    // console.log('Div height:', divHeight);
    socket.on('usersOn', this.getUsers)
  }

  componentWillUnmount() {
    // this._isMounted = false
    // socket.off("setUserStatusWork")
    socket.off("usersOn", this.getUsers)
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    const divHeight = this.myDivRef.current.offsetHeight;
    this.setState({
      divHeight: (divHeight + 39)
    })
  }
  removeNull(obj) {
    let a = obj
    delete a.null
    return a
  }

  getUsers = async (_assets) => {
    const { user, assets } = content()
    const { assetId } = this.context.state
    // console.log(this.context.state)
    // const assetId = props?.assetId
    // const assets = d?.assets
    var filtered = _assets?.null ? this.removeNull(_assets) : _assets
    const keys = Object.keys(filtered)
    let users = []
    for (var key of keys) {
      for (var asset of _assets[key]) {
        users.push(asset)
      }
    }
    const _user = users.find((f) => f?.userId === user?.userId)
    // console.log({
    //   user,
    //   assets,
    //   users,
    //   _user,
    //   _assets
    // })
    if (_user) {
      const _asset = assets.find((f) => f?._id === _user?.assetId)
      // console.log(_asset)
      // this.context.contextCall("hightLight", _asset)
      this.context.contextCall("assetId", _asset?._id)
      this.context.contextCall("_assetGlobal", _asset)
      this.context.contextCall("asset_name", _asset?.asset_name)
      this.context.contextCall("assetImg", _asset?.param?.asset_image)
      this.setState({
        indexKey: assetId,
        // Component: widgets["Assets"]
      })
    } else {
      this.context.contextCall("assetId", null)
      this.context.contextCall("_assetGlobal", null)
      this.context.contextCall("asset_name", null)
      this.context.contextCall("assetImg", null)
      this.context.contextCall("_UsersOn", null)
      this.setState({
        Component: widgets["Assets"]
      })
    }
    // const users = assets?.[assetId]
    // return users.map((el, i) => ({ ...el, key: i + 1 }))
  }

  getData = async () => {
    const { dataProps } = this.state
    if (dataProps && dataProps.debug) {
      console.log(this.state)
    }
    document.addEventListener("keydown", this.escFunction, false);
    // document.addEventListener("fullscreenchange", this.fullscreenchange, false);
    const { fullScreen, authToken, userConfig, lang } = this.context.state
    const { user } = userConfig
    const { fullscreen } = dataProps
    const _fullscreen = dataProps.fullscreen && checkMobile()
    if (fullScreen || _fullscreen) {
      this.handleFullScreen()
    }
    // NOTE socket
    // socketConn.on("test", (data) => {
    //   console.log(data)
    // })
    if (this._isMounted) {
      const result = await allinOne("getSpecDocument", {})
      if (result?.assets) {
        const a = await this.getUsers(result?.assets)
      }
      const _user = await ItemCatalog("User", authToken, "param.pauseStatus AS pauseStatus, param", user.userId)
      // this.setState({ pauseStatus: _user.pauseStatus })
      if (_user && _user.pauseStatus) {
        this.setState({ Component: widgets["Pause"], drawer: true, children: _user, ModalUsersOn: false })
      }
    }
  }

  handleButton = async (el, type) => {
    // console.log({el, type})
    const _Component = el?.widgets ? el.widgets[0][0][0].component : undefined
    const _children = el?.widgets ? el.widgets[0][0][0].children : undefined
    const Component = widgets[widgets[_Component] ? _Component : "FileNotExist"]
    this.setState({ Component, sequence: el?.runNum, children: _children, modal: true, ModalUsersOn: false })
    if (el.route === "/assets") {
      // this.removeSelectedAsset()
    }
  }

  handleUsersOn = async (el, type) => {
    const c = await import("src/layouts/widgets/Companies/i4c_V2/main/UsersOn");
    const Comp = c?.default
    this.setState({ ModalUsersOn: Comp })
  }

  removeSelectedAsset = () => {
    this.setState({
      Component: widgets["Assets"],
      sequence: 0,
      selected: false,
      asset_name: "",
      _image: "",
      children: null,
      props: null,
      // fullscreen: false,
      indexKey: Math.random()
    })
    this.context.contextCall("hightLight", false)
    this.context.contextCall("_assetGlobal", null)
  }

  setNewData = (e) => {
    this.setState({ activeKey: e, indexKey: e })
  }

  setNewUsersOnAsset = (el) => {
    this.getUsers(el)
  }

  setUserToAsset = (e, call, assets) => {
    // console.log(e)
    const f = async (e) => {
      this.setState({
        usersOn: e,
        userProps: false,
        ModalUsersOn: false,
        isLoading: false
      })
    }
    if (assets) {
      this.context.contextCall("assetId", e?.assetId)
      this.setState({
        usersOn: assets,
        userProps: false,
        ModalUsersOn: false,
        isLoading: false
      })
    } else {
      this.context.contextCall("assetId", e?.assetId)
      this.setState({ isLoading: true })
      updateUserAssetMgmt(e, f, call)
    }
  }

  callback = (e) => {
    // console.log(e)
    this.setState({ mount: true })
    if (e && e.type === "removeAsset") {
      return null
    }
    if (e && e.type === "pauseStatus") {
      // console.log(e)
      const { history } = this.props
      const { pages } = this.context.state.userConfig.pages
      const findPage = pages.find((el) => el._id === e.redirect)
      // console.log(findPage)
      const _children = findPage ? findPage.widgets[0][0][0].children : undefined
      // console.log(findPage)
      if (_children) {
        this.setState({
          Component: widgets["OperationOrder"],
          sequence: e.sequence,
          children: _children,
          _image: e.param.asset_image,
          // asset_name: e.asset_name,
          props: e,
          selected: true
        })
      } else {
        this.setState({
          Component: widgets["Assets"],
          sequence: 0,
          selected: false,
          fullscreen: false
        })
      }
      this.context.contextCall("asset_name", e.asset_name)
    } else {
      const { pages } = content()
      // const { pages } = this.context.state.userConfig.pages
      if (e && e?.redirect) {
        // dumb ways to dieEvelop
        // wada mistejka to mejka
        const p = pages?.pages ?? []
        const findPage = p?.find((el) => el._id === e.redirect)
        const _children = findPage.widgets ? findPage.widgets[0][0][0].children : undefined
        // console.log(state)
        // 4f0a19bc-3414-4cd4-bcc0-51dc44d09478
        this.setState({
          Component: widgets["OperationOrder"],
          sequence: e.sequence,
          children: _children,
          _image: e?.param?.asset_image,
          // asset_name: e.asset_name,
          props: e,
          selected: true
        }, () => this.setUserToWork(e))
        this.context.contextCall("_assetGlobal", e)
        this.context.contextCall("asset_name", e.asset_name)
      } else {
        this.setState({
          Component: widgets["Assets"],
          sequence: 0,
          selected: false,
          fullscreen: false
        })
      }
    }
  }

  setUserToWork = (e) => {
    const { userConfig, authToken } = this.context.state
    const { user } = userConfig
    socket.connect()
    socket.emit("setUserStatusWork", {
      param: {
        ...e,
        userId: user.userId,
        user: user.firstName + " " + user.lastName,
        timestamp: Date.now(),
        authToken
      }
    })
  }

  openFullscreen = () => {
    document.addEventListener('fullscreenchange', this.handleFullScreenChange)
  }

  closeModal = () => {
    this.setState({
      ModalUsersOn: false
    })
  }

  render() {
    const { clientUrl } = content()
    // console.log(content())
    const { style } = this.context
    const { lang, userConfig, undefObjHandler, asset_name, assetImg, assetId } = this.context.state
    const { user } = userConfig
    const { pages } = this.context.state.userConfig.pages
    const { divHeight, Component, _image, sequence, children, props, indexKey, usersOn, ModalUsersOn, userProps, isLoading } = this.state
    const _pages = pages.filter((el) => el.activeStatus && el.published && !el.isProtected)
    // const _pages = [...__pages]
    const colors = user.param ? {
      ...user.param
    } : ""
    // const assetId = this?.state?.props?._id
    // console.log(assetId)
    // console.log(ModalUsersOn)
    // console.log(this.context.state)
    return (
      <>
        <div
          ref={this.myDivRef}
          style={{
            position: "fixed",
            width: "100%",
            top: 20,
            background: "#ffffff",
            zIndex: 2
          }}>
          {(_pages && _pages.length > 0) && _pages.map((el, i) => {
            const _styleSpan = (el.runNum === sequence) ? { fontWeight: (el.runNum === sequence) ? "bold" : "" } : {}
            const _styleButton = (el.runNum === sequence) ? { background: colors.highlightButtontColor } : { background: colors.backgroundButtonColor }
            const exception = (el.pageName === "Pause") ? { ...style.header.buttonInterruptAsPage } : { display: el.pageName === "Pause" ? "none" : "", fontWeight: "" }
            const iconDef = undefObjHandler(el, "icon") ? el.widgets[0][0][0].children.icon : false
            const icon = iconDef && renderIconV2(iconDef.name, iconDef.size, iconDef.color)
            // console.log(iconDef.name)
            // const icon = undefObjHandler(el, "icon") ? renderIconV2(el.widgets[0][0][0].children.icon.name, 50, "") : false

            return (
              <span key={JSON.stringify(i)} style={{ width: "100%", marginLeft: 10 }}>
                <Tooltip placement="bottom" title={(el.pageName === "Pause") ? (lang?.[el.title] ?? el.title) : (lang?.[el.pageName] ?? el.pageName)}>
                  <Button
                    style={{ ...style.header.buttonCustomHeader, ..._styleButton, maxHeight: 200, maxWidth: 200, marginRight: 10, border: "1px solid #e7e7e7", ...exception }}
                    onClick={() => this.handleButton(el)}
                    hidden={(!assetId && (el.pageName === "DeviceDashboard"))}>
                    {(el.runNum === 0) ? <Image preview={false} style={{ position: "relative", maxHeight: 35, maxWidth: 60 }} fallback={"/media/users/defaultMachine.jpg"} src={clientUrl + "/media/users/" + assetImg} />
                      : <span style={{ ..._styleSpan }}>{icon} {(el.pageName === "Pause") ? "" : limitText((lang?.[el.title] ?? el.title), 10)}</span>}
                    <div style={{ fontSize: 10, color: colors.textColor }}>{(el.runNum === 0) ? limitText((asset_name ? asset_name : (lang?.["Select"] ?? "Select")), 10) : limitText((lang?.[el.title] ?? el.title), 10)}</div>
                  </Button>
                </Tooltip>
              </span>
            )
          })}
          <FloatContent />
          <Header />
          {/*
        <Tooltip placement="bottom" title={"lčkm"}>
          <Button
            style={{ ...style.header.buttonCustomHeader, }}>
            sdfsd
            <Tooltip placement="left" title={(asset_name ? asset_name : "Select")}>
              <div style={{ fontSize: 10 }}>
                sdfdsf
              </div>
            </Tooltip>
          </Button>
        </Tooltip>
        */}
        </div>
        <div style={{ position: "fixed", top: divHeight }}>
          <Component
            key={assetId}
            children={children}
            setNewData={this.setNewData}
            setNewUsersOnAsset={this.setNewUsersOnAsset}
            setUserToAsset={this.setUserToAsset}
            callback={this.callback}
            dataProps={{ ...this.state }} />
        </div>
        {(ModalUsersOn) && <ModalUsersOn key={JSON.stringify(ModalUsersOn)} closeModal={this.closeModal} />}
        {(isLoading) && <Spinner />}
      </>
    )
  }
}

export default withRouter(DashboardV2);
